<template>
  <div>
    <div v-if="loading">
      <div class="d-flex justify-content-center text-center mx-5 mt-3 mb-5">
        <div class="spinner-border text-success align-self-center loader-lg" />
      </div>
    </div>
    <div v-else-if="currentUser">
      <!-- Companions -->
      <b-row>
        <div
          v-for="(companion, cIdx) in allCompanions"
          :key="cIdx"
          class="col-6"
        >
          <b-form-group v-if="companion" class="col-12">
            <div class="text-center user-info">
              <b-img
                class="border-radius-50-percent max-width-profile-picture"
                :src="companion.profile.picture"
                alt="avatar"
              />
              <!-- break-word -->
              <div class="m-3 mb-5 font-size-big font-weight-bold overflow-hidden">
                <span>{{ companion.profile.name }}</span>
                <p
                  v-if="
                    companion.companionStudentSettings.spokenLanguages.length >
                      0
                  "
                >
                  <span
                    v-for="(language, lIdx) in companion
                      .companionStudentSettings.spokenLanguages"
                    :key="lIdx"
                  >
                    <country-flag
                      v-if="parseInt(lIdx, 10) === 0"
                      :country="language"
                      size="normal"
                      class="mb-n3"
                    />
                    <country-flag
                      v-else-if="parseInt(lIdx, 10) > 0"
                      :country="language"
                      size="normal"
                      class="mb-n3"
                    />
                  </span>
                </p>
              </div>
              <span
                v-if="!isFromSoulLetterWithoutComp"
                class="bg-primary p-3 border-radius-5px font-size-middle font-weight-bold"
              >
                {{
                  companion.companionStudentSettings.prices.conversation
                }}€/Min.
              </span>
            </div>
            <b-row v-if="currentUser" class="flex-column m-3 mt-4">
              <button
                @click="chooseCompanion(companion)"
                :disabled="currentUser._id === companion._id"
                class="btn btn-success m-1 shadow-none"
              >
                <b-img class="feathericons-edit-2 p-3 bg-white" />
                <span class="ml-2">{{ $t("companions.bookSoulLetter") }}</span>
              </button>
            </b-row>
          </b-form-group>
        </div>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as userApi from "@/store/modules/users/users.api";
import { EUserRoles } from "@/store/modules/users/EUserRoles";

export default {
  name: "CompanionSelection",
  props: {
    setChosenCompanion: Function,
    isFromSoulLetterWithoutComp: Boolean
  },
  data() {
    return {
      loading: false,
      loadingError: false,
      currentUser: null,
      allUsers: [],
      allCompanions: [],
      chosenCompanion: null
    };
  },
  computed: {
    ...mapGetters("users",["getCurrentUser"])
  },
  methods: {
    chooseCompanion(companion) {
      this.setChosenCompanion(companion);
    },
    getAllUsers() {
      this.loading = true;
      this.loadingError = false;
      userApi
        .getAllUsers()
        .then(res => {
          const users = res.data.data;
          this.allUsers = users;
          this.allUsers.forEach(user => {
            if (user.role === EUserRoles.COMPANION) {
              this.allCompanions.push(user);
            }
          });
          this.loading = false;
          this.loadingError = true;
        })
        .catch(err => {
          this.loading = false;
          this.loadingError = false;
        });
    }
  },
  created() {
    if (this.getCurrentUser) {
      this.loading = true;
      this.currentUser = this.getCurrentUser;
      this.loading = false;
    }
    this.getAllUsers();
  }
};
</script>

<style scoped></style>
